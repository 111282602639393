export const baseURL = "/employee-application";
export const baseRenewalURL = "/employee-renewal";
export const baseURLHelper = (isRenewal: boolean) => (isRenewal ? baseRenewalURL : baseURL);

export const isRenewal = () => window.location.pathname.includes("/employee-renewal");

// A helper check, used when we need routing and render differences
// between the New Enrollment Flow vs. Post Enrollment Flow
export const inNewEnrollmentFlow = () => !!window.location.pathname.includes("employer-application/company-admin");

export const adminEmployeeParam = "employee_hash_id";
export const adminBaseURL = `/company-admin${baseURL}/:${adminEmployeeParam}`;
export const adminBaseRenewalURL = `/company-admin${baseRenewalURL}/:${adminEmployeeParam}`;

// TODO: We have a codebase wide issue here - we are using "next URL" with unique customer ID tokens, incorrectly as our routes.
// Routes should look like routes:
// /:partner(intuit|square|bambee)/router/:company(company)/:companyIdToken/employer-application/company-admin/:employee_hash_id/
// /:company(company)/:companyIdToken/employer-application/company-admin/:employee_hash_id/
// /:partner(intuit|square|bambee)/router/employer-application/company-admin/:employee_hash_id/
// /employer-application/company-admin/:employee_hash_id/
// Strings we use for changing the current URL should have parameters filled in
// /intuit/router/company/8053hr/employer-application/company-admin/employee-application/pvjwdq/
const urlHelper = (url: string) => (isAdminMode: boolean, isRenewal: boolean, id: string | null | undefined, isRoute: boolean) => {
  const employerApplicationModeString = window.location.pathname.includes("employer-application") ? "/employer-application" : "";
  const idString = isRoute ? ":employee_hash_id" : id || "";
  return isAdminMode ? `${employerApplicationModeString}/company-admin${baseURLHelper(isRenewal)}/${idString}${url}` : `${baseURLHelper(isRenewal)}${url}`;
};

export const basicInformationURL = urlHelper("/");
export const familyInformationURL = urlHelper("/family-information");
export const chooseMedicalPlanURL = urlHelper("/choose-medical-coverage");
export const chooseDentalPlanURL = urlHelper("/choose-dental-coverage");
export const chooseVisionPlanURL = urlHelper("/choose-vision-coverage");
export const additionalInformationURL = urlHelper("/additional-information");
export const signURL = urlHelper("/sign");
export const doneURL = urlHelper("/done");
export const jumpURL = urlHelper("/jump");

export const isAnEmployeeApplicationPage = () => window.location.pathname.indexOf(baseURL) !== -1;

// Transform a route into an array of routes, to handle the various ways we use the EmployeeApplication
// Convert this to a nested route in v6
export const nestedEmployeeApplicationRoutes = (url) => [
  `/employee-application${url}`,
  `/company-admin/employee-application/:employee_hash_id${url}`,
  `/employer-application/company-admin/employee-application/:employee_hash_id${url}`,
];

export const nestedEmployeeRenewalApplicationRoutes = (url) => [`/employee-renewal${url}`, `/company-admin/employee-renewal/:employee_hash_id${url}`];
